<template>
  <div>
    <Dialog header="Bild löschen" v-model:visible="askDelete" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-[color:var(--delete-color)]" style="font-size: 2rem" />
        <span>Soll das Bild wirklich gelöscht werden?</span>
      </div>
      <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" @click="askDelete = false" class="p-button-text" autofocus />
        <Button label="Löschen" icon="pi pi-trash" @click="deletePicture(selectedPictureId)" class="p-button-delete" />
      </template>
    </Dialog>
    <div v-if="isLoading" class="flex h-[460px] flex-col justify-around">
      <div class="flex flex-col text-center">
        <ProgressSpinner />
        <span class="mt-2">Bilder werden geladen .... </span>
      </div>
    </div>
    <div v-if="pictures.length > 0 && selectedPictureId != 0">
      <div class="m-auto w-fit">
        <img
          class="mx-auto h-56 max-w-lg cursor-pointer rounded-sm object-contain md:h-80"
          :src="pictures.find((p) => p.id === selectedPictureId)?.url"
          @click="openPicture(selectedPictureId)"
        />
        <i class="pi pi-trash" id="deleteIcon" @click="askDelete = true" v-if="edit"></i>
      </div>
      <div class="mt-3 flex w-full flex-row overflow-auto">
        <img
          v-for="picture in pictures"
          :key="picture.id"
          class="h-24 w-24 flex-shrink-0 cursor-pointer border border-gray-300 object-contain md:h-32 md:w-32"
          :class="{ 'border-primary-700': picture.id === selectedPictureId }"
          :src="picture.url"
          @click="selectedPictureId = picture.id"
        />
      </div>
    </div>
    <div class="mt-5 overflow-auto" v-if="edit">
      <div v-if="isUploading" class="flex w-full flex-col">
        <div class="mb-1">Bild wird hochgeladen ...</div>
        <ProgressBar mode="indeterminate" />
      </div>

      <label for="file-upload" class="p-button p-component float-right inline-flex" v-else>
        <span>Bild hinzufügen</span>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          accept="image/*"
          class="sr-only"
          @change="uploadPicture"
        />
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { definitions } from '@/generated/types/database'
  import { DevicePicture, getPicturesForDevice } from '@/helper/storage'
  import { supabase } from '@/supabase'
  import { reactive, ref } from 'vue'
  import Compressor from 'compressorjs'
  import { useToast } from 'primevue/usetoast'
  import { useRouter } from 'vue-router'

  const props = defineProps({
    deviceId: { type: String, required: true },
    edit: { type: Boolean, default: false },
  })
  const toast = useToast()
  const router = useRouter()

  let isLoading = ref(true)
  let pictures: DevicePicture[] = reactive([])
  let selectedPictureId = ref(0)
  let isUploading = ref(false)
  const askDelete = ref(false)

  getPicturesForDevice(parseInt(props.deviceId)).then((pics) => {
    pictures.push(...pics)
    if (pictures.length > 0) selectedPictureId.value = pictures[0].id
    isLoading.value = false
  })

  const uploadPicture = async (e: Event) => {
    isUploading.value = true
    //@ts-ignore
    const file = e?.target?.files[0]
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 2048,
      maxHeight: 2048,

      async success(resultFile) {
        if ('name' in resultFile) {
          const fileExt = resultFile.name.split('.').pop()
          const fileName = `${props.deviceId}${Math.random()}.${fileExt}`
          const { error: uploadError } = await supabase.storage.from('device-picture').upload(fileName, resultFile)
          if (uploadError) throw uploadError

          try {
            const { data, error } = await supabase
              .from('g4y_device_picture')
              .insert({ device_id: props.deviceId, url: fileName })
            if (error || data === null) throw error
            const test: definitions['g4y_device'] | null = null
            const newPictureObject = <definitions['g4y_device_picture']>data[0] // eslint-disable-line identifier
            pictures.push({ id: newPictureObject.id, url: URL.createObjectURL(resultFile), urlInStorage: fileName })
            selectedPictureId.value = newPictureObject.id
          } catch (error) {
            console.trace(error)
          } finally {
            isUploading.value = false
          }
        }
      },
      error(err) {
        console.log(err.message)
        isUploading.value = false
      },
    })
  }

  const deletePicture = async (id: number) => {
    askDelete.value = false
    try {
      const picture = pictures.find((p) => p.id === id)
      if (picture && picture.urlInStorage) {
        await supabase.storage.from('device-picture').remove([picture.urlInStorage])
        await supabase.from('g4y_device_picture').delete().eq('id', id)
        const db_entry_result = await supabase.from('g4y_device_picture').select().eq('id', id)
        if (db_entry_result.data?.length != 0) throw new Error('DB entry not deleted. Maybe no rights to delete?')
        const pictureInStorage = await supabase.storage.from('device-picture').createSignedUrl(picture.urlInStorage, 60)
        console.log(pictureInStorage)
        if (!pictureInStorage.error && pictureInStorage.signedURL?.length != 0)
          throw new Error('DB entry not deleted. Maybe no rights to delete?')

        pictures = pictures.filter((p) => p.id !== id)
        if (pictures.length > 0) selectedPictureId.value = pictures[0].id
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Bild wurde gelöscht', life: 3000 })
      }
    } catch (error) {
      console.trace(error)
      toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Bild konnte nicht vollständig gelöscht werden',
        life: 3000,
      })
    }
  }

  const openPicture = (id: number) => {
    const picture = pictures.find((p) => p.id === id)
    if (picture && picture.url) window.open(picture.url, '_blank')
  }
</script>
<style lang="scss">
  .p-button {
    //copy from g4y_theme.scss
    &:hover {
      background-color: var(--primary-color-700);
      border-color: var(--primary-color-700);
      color: var(--secondary-color);
    }
  }
  .p-progressbar {
    height: 0.55rem;
    .p-progressbar-value {
      background-color: var(--primary-color-700);
    }
  }

  #deleteIcon {
    font-size: 1.25rem;
    color: --primary-color-700;
    float: right;
    position: relative;
    top: -1.75rem;
    right: 0.5rem;
    &:hover {
      color: red;
    }
  }
</style>
