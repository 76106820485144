import { Person, Contact } from '@/types/extendDatabase'

export const getPersonName = (person: Person) => {
  if (!person.first_name) return person.last_name
  if (!person.last_name) return person.first_name
  return `${person.first_name} ${person.last_name}`
}

export const getCustomerLabel = (contact: Contact) => {
  return `${contact.name} [${contact.mail}]`
}
