<template>
  <div class="w-fit rounded bg-gray-200 p-6">
    <h3 class="w-64 font-bold">Betriebsstunden (gesamt)</h3>
    <div>
      <table v-if="operatingHours?.length > 0" class="mt-4 w-full">
        <tr class="border-b-2">
          <th class="w-1/2 border-r-2 border-white p-1">Datum</th>
          <th class="w-1/2 p-1">Wert [h]</th>
          <!-- <th>Kommentar</th> -->
        </tr>
        <tr v-for="operatingHour in operatingHours" class="border-b-2 border-gray-200 bg-white">
          <td class="border-r-2 border-gray-200 p-1 text-center">{{ formatDate(new Date(operatingHour.date)) }}</td>
          <td class="p-1 text-right">{{ operatingHour.value }}</td>
          <!-- <td>{{ operatingHour.comment }}</td> -->
        </tr>
      </table>

      <div
        class="mt-2 text-right text-primary hover:cursor-pointer hover:text-secondary"
        @click="addEntryState = true"
        v-if="!addEntryState"
      >
        Eintrag hinzufügen
      </div>
      <div v-if="addEntryState" class="mt-6">
        <label for="date" class="mt-2 block text-sm font-medium text-neutral">Datum</label>
        <Calendar id="date" v-model="date" show-icon />
        <label for="value" class="mt-2 block text-sm font-medium text-neutral">Wert</label>
        <InputNumber id="value" v-model="value" :min="0" :step="1" show-buttons />
        <!-- <label for="comment" class="mt-2 block text-sm font-medium text-neutral">Kommentar</label>
      <InputText id="comment" v-model="comment" /> -->
        <div class="mt-6 flex justify-end">
          <Button @click="addEntryState = false" class="p-button-outlined p-button-sm block">Abbrechen</Button>
          <Button @click="addEntry" class="p-button-sm !ml-2">Speichern</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { definitions } from '@/generated/types/database'
  import { formatDate } from '@/helper/date'
  import { supabase } from '@/supabase'
  import { useToast } from 'primevue/usetoast'
  import { Ref, onMounted, ref } from 'vue'

  const props = defineProps({
    deviceId: {
      type: Number,
      required: true,
    },
  })

  const toast = useToast()

  const operatingHours: Ref<definitions['device_operating_hours'][]> = ref([])
  const addEntryState = ref(false)
  const date: Ref<Date | undefined> = ref()
  const value = ref(0)
  const comment = ref()

  const load = async () => {
    try {
      const { data, error } = await supabase.from('device_operating_hours').select('*').eq('device_id', props.deviceId)
      if (error) throw error
      operatingHours.value = data
    } catch (error) {
      console.error(error)
    }
  }

  onMounted(() => {
    if (!props.deviceId) return
    load()
  })

  const addEntry = async () => {
    try {
      const { error } = await supabase.from('device_operating_hours').insert([
        {
          device_id: props.deviceId,
          date: date.value,
          value: value.value,
          // comment: comment.value,
        },
      ])
      if (error) throw error
      load()
      addEntryState.value = false
      toast.add({
        severity: 'success',
        summary: 'Erfolgreich',
        detail: 'Eintrag hinzugefügt',
        life: 5000,
      })
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Eintrag konnte nicht hinzugefügt werden',
        life: 5000,
      })
      console.error(error)
    }
  }
</script>
