<template>
  <div
    v-if="test"
    class="flow-row flex justify-between border-b border-gray-200 px-5 py-2 font-medium"
    :class="{
      ' border-t-1 border-b-0 border-solid border-red-900': isActive,
      'cursor-pointer': !isActive,
    }"
  >
    <div class="flow-row flex">
      <div
        class="h-5 w-5 flex-shrink-0 flex-col"
        v-if="!onlyList"
        :class="{ 'w-10': device.press_state.level > 0 && false }"
      >
        <div class="flex">
          <img v-if="device.press_state.level === 1" src="@/assets/trash_75.png" class="h-4 w-4" />
          <img v-else-if="device.press_state.level === 2" src="@/assets/trash_100.png" class="h-4 w-4" />
          <div v-if="device.press_state.level > 0 && device.press_state.state > 0" class="mx-1"></div>
          <i
            v-if="device.press_state.state && device.press_state.state != 10"
            class="pi pi-exclamation-triangle mr-4 text-red-700"
            style="font-size: 0.9rem"
          />
        </div>
      </div>
      <div class="ml-3 text-sm" :class="{ 'font-bold': isActive }">{{ device.name }}</div>
      <!-- <span class="material-symbols-outlined my-auto ml-2 text-green-700" v-if="device.released && right_device_release"
        >task_Alt</span
      > -->
    </div>
    <XIcon v-if="isActive" class="w-3 cursor-pointer" :class="{ '!w-5': isSmallScreen }" @click.stop="$emit('close')" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { IGNITION_OFF, IGNITION_ON, IGNITION_ON_AND_MOVEMENT } from '@/consts'
  import { XIcon } from '@heroicons/vue/solid'
  import { DeviceWithPropertiesAndPressState } from '@/types/extendDatabase'
  import { useAuthStore } from '@/store/auth'
  import { useUserDeviceStore } from '@/store/userDevice'
  import { useDeviceGroupStore } from '@/store/deviceGroup'

  export default defineComponent({
    components: { XIcon },
    props: {
      device: {
        type: Object as () => DeviceWithPropertiesAndPressState,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      onlyList: {
        type: Boolean,
        default: false,
      },
      onlyListPosition: {
        type: Number,
        default: 0,
      },
    },
    emits: ['close'],
    setup(props) {
      const groupStore = useDeviceGroupStore()
      const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)

      let test = ref(true)
      const classCircleColor = computed(() => {
        if (groupStore.isBleType(props.device.group_id)) {
          return 'bg-gray-300'
        } else if (props.device.state === IGNITION_OFF) {
          return 'bg-ignition-off'
        } else if (props.device.state === IGNITION_ON) {
          return 'bg-ignition-on'
        } else if (props.device.state === IGNITION_ON_AND_MOVEMENT) {
          return 'bg-ignition-on-and-movement'
        }
        return ''
      })

      const classBorderColor = computed(() => {
        if (groupStore.isBleType(props.device.group_id)) return 'border-gray-300'
        switch (props.device.state) {
          case 0:
            return 'border-red-700'
          case 1:
            return 'border-yellow-600'
          default:
            return 'border-green-900'
        }
      })

      return {
        classCircleColor,
        classBorderColor,
        props,
        test,
        isBleType: groupStore.isBleType(props.device.group_id),
        right_device_release: useAuthStore().userRights.right_device_release,
        isSmallScreen,
      }
    },
  })
</script>
