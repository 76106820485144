import { IGNITION_OFF, IGNITION_ON, IGNITION_ON_AND_MOVEMENT } from '@/consts'
import { definitions } from '@/generated/types/database'
import { Device, DeviceProperty, DeviceWithProperties } from '@/types/extendDatabase'

function getDeviceState(ignition: boolean, movement: boolean): number {
  return !ignition ? IGNITION_OFF : !movement ? IGNITION_ON : IGNITION_ON_AND_MOVEMENT
}

export const prepareDevice = (
  device_in: definitions['g4y_device'] & { properties: DeviceProperty[] }
): DeviceWithProperties => {
  const date = device_in.updated != undefined ? new Date(device_in.updated + '+00:00') : new Date('1970-01-01')
  const device: Device & { properties: DeviceProperty[] } = {
    //@ts-ignore
    position: device_in.position ?? { type: 'Point', coordinates: [0, 0] },
    updated: date,
    state: getDeviceState(device_in.ignition ?? false, device_in.movement ?? false),
    id: device_in.id,
    ident: device_in.ident,
    properties: device_in.properties,
  }
  for (const [key, value] of Object.entries(device_in)) {
    if (!key.localeCompare('name')) {
      device.name = device_in.name ?? 'ohne Namen'
    } else if (key != 'position' && key != 'updated') {
      //@ts-ignore
      device[key] = value
    }
  }
  return device
}
