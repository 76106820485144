import { propertyTypeDataTypes } from '@/consts'
import { definitions } from '@/generated/types/database'
import { usePropertyStore } from '@/store/property'
import { DeviceProperty } from '@/types/extendDatabase'
import { formatDate } from './date'


export type PropertyDataTypeString =
  | 'value_int'
  | 'value_float'
  | 'value_text'
  | 'value_date'
  | 'value_select_id'
  | 'value_boolean'

export const getDataTypeLabelHelper = (property_type_id: number, data_type_id: number): PropertyDataTypeString => {
  switch (propertyTypeDataTypes[data_type_id]) {
    case 'INTEGER':
      return 'value_int'
    case 'FLOAT':
      return 'value_float'
    case 'STRING':
    case 'TEXT':
      return 'value_text'
    case 'DATE':
    case 'SERVICE_DATE':
      return 'value_date'
    case 'SELECT':
      return 'value_select_id'
    case 'BOOLEAN':
      return 'value_boolean'
    default:
      throw new Error('Unknown data type')
  }
}

export const isMaintenanceDate = (property: DeviceProperty | undefined): boolean => {
  const propertyStore = usePropertyStore()
  if (!property) return false
  const propertyType: definitions['device_property_type'] = propertyStore.propertyTypeById(property.property_type_id)
  return propertyTypeDataTypes[propertyType.data_type] === 'SERVICE_DATE'
}

export const getFormattedValue = (property: DeviceProperty | undefined): string => {
  const propertyStore = usePropertyStore()
  if (!property) return ''
  const propertyType: definitions['device_property_type'] = propertyStore.propertyTypeById(property.property_type_id)
  switch (propertyTypeDataTypes[propertyType.data_type]) {
    case 'INTEGER':
      let returnString = `${((property.value_int ?? 0) / (propertyType.factor ?? 1)).toLocaleString('de')}${
        propertyType.unit ? ' ' + propertyType.unit : ''
      }`
      return returnString
    case 'FLOAT':
      return `${(property.value_float ?? 0).toLocaleString('de')}${propertyType.unit ? ' ' + propertyType.unit : ''}`
    case 'DATE':
      return formatDate(new Date(property.value_date!))
    case 'SERVICE_DATE':
      const date = new Date(property.value_date!)
      return `${date.getMonth() + 1}/${date.getFullYear()}`
    case 'SELECT':
      return propertyStore.propertyTypeSelectOptionLabelById(property.value_select_id!)
    case 'BOOLEAN':
      return property.value_boolean ? 'Ja' : 'Nein'
    default:
      return '' + property[getDataTypeLabel(property.property_type_id)]
  }
}

export const getDataTypeLabel = (property_type_id: number) => {
  const propertyStore = usePropertyStore()
  return getDataTypeLabelHelper(property_type_id, propertyStore.propertyTypeById(property_type_id).data_type)
}

export const findIndexByPropertyTypeId = (
  properties: { property_type_id: number }[],
  property_type_id: number
): number => {
  return properties.findIndex(
    (property: { property_type_id: number }) => property.property_type_id === property_type_id
  )
}

export const findPropertyByPropertyTypeId = (
  properties: DeviceProperty[],
  property_type_id: number
): DeviceProperty | undefined => {
  return properties.find((property: DeviceProperty) => property.property_type_id === property_type_id)
}
