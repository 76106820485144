<template>
  <div ref="mapRoot" class="h-full"></div>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import * as ol from 'ol'

  import 'ol/ol.css'
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import { Geometry, Point } from 'ol/geom'
  import { getStyleByGroupId, transformToOl } from '@/controller/MapHelper'
  import TileLayer from 'ol/layer/Tile'
  import OSM from 'ol/source/OSM'

  const props = defineProps<{ lat: number; long: number; label: string; group_id?: number }>()
  const mapRoot = ref(null)
  const pos = [props.lat, props.long]

  let f = new ol.Feature({
    geometry: new Point(transformToOl(pos)),
    name: props.label,
  })
  f.setStyle(getStyleByGroupId(props.group_id))

  let vectorLayer: VectorLayer<VectorSource<Geometry>> = new VectorLayer({
    source: new VectorSource({
      features: [f],
    }),
  })

  onMounted(() => {
    let view = new ol.View({
      center: transformToOl(pos),
      zoom: 17,
    })
    let myMap = new ol.Map({
      //@ts-ignore
      target: mapRoot.value,
      layers: [new TileLayer({ source: new OSM() }), vectorLayer],
      view,
      // interactions: [],
    })
  })
</script>
