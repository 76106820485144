import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { BranchOffice } from '@/types/extendDatabase'
import { ref, Ref } from 'vue'
import { TreeNode } from 'primevue/tree'
import { useRightsStore } from './rights'

export const useBranchOfficeStore = defineStore('branchOffice', () => {
  const branchOffices: Ref<BranchOffice[]> = ref([])
  const branchMap: Ref<Map<number, BranchOffice>> = ref(new Map())
  const branchOfficesAsTree: Ref<TreeNode[]> = ref([])
  const rightsStore = useRightsStore()

  async function fetchBranchOffices() {
    try {
      const { data, error } = await supabase.from('branch_office').select('*')
      console.log(data)
      if (error) throw error
      branchOffices.value = data
      branchOfficesAsTree.value = buildTree(data)
    } catch (error: any) {
      console.error(error)
    }
  }

  ;(async () => {
    await rightsStore.initDone
    fetchBranchOffices()
  })()

  function buildTree(list: BranchOffice[]): TreeNode[] {
    for (const item of list) {
      branchMap.value.set(item.id, item)
    }
    const right = rightsStore.rights.filter((r) => r.user_id === supabase.auth.user()?.id)[0]
    const init = branchMap.value.get(right.assigned_branch_office)!
    const node = {
      label: init.label ?? 'Ohne Namen',
      key: init.id.toString(),
      children: genChild(init.child_ids),
      data: init,
    }
    return [node]
  }

  function genChild(childs: number[]): TreeNode[] {
    if (childs.length === 0) return []
    const nodes: TreeNode[] = []
    for (const childId of childs) {
      let child = branchMap.value.get(childId)!
      nodes.push({
        label: child.label ?? 'Ohne Namen',
        key: child.id.toString(),
        children: genChild(child.child_ids),
        data: child,
      })
    }
    return nodes
  }

  function getBranchById(id: number) {
    return branchMap.value.get(id)
  }

  return { branchOffices, fetchBranchOffices, branchOfficesAsTree, getBranchById }
})
