import { definitions } from '@/generated/types/database'
import { prepareDevice } from '@/helper/deviceHelper'
import { supabase } from '@/supabase'
import { Device, DeviceProperty, DeviceWithProperties } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

export const useDeviceStore = defineStore(
  'deviceStore',
  () => {
    let devices_backend: DeviceWithProperties[] = []
    let devices: Ref<DeviceWithProperties[]> = ref([])
    const indexes: Map<number, number> = new Map()
    let updatedDevices: Ref<number[]> = ref([])

    async function fetchDevices() {
      try {
        const { data, error } = await supabase
          .from('g4y_device')
          .select('*, properties:device_property(*)')
          .eq('properties.overridden', false)
        if (error) throw error
        const fetchedDevices = <(definitions['g4y_device'] & { properties: DeviceProperty[] })[]>data
        devices_backend = []
        for (const fetchedDevice of fetchedDevices) {
          devices_backend.push(prepareDevice(fetchedDevice))
        }
        devices.value = devices_backend
        indexes.clear()
      } catch (error) {
        console.error(error)
      }
    }

    async function updateDeviceFromServerById(id: number) {
      try {
        const { data, error } = await supabase
          .from('g4y_device')
          .select('*, properties:device_property(*)')
          .eq('properties.overridden', false)
          .eq('id', id)
          .single()
        if (error) throw error
        const fetchedDevice = <definitions['g4y_device'] & { properties: DeviceProperty[] }>data
        updateDeviceWithProperties(prepareDevice(fetchedDevice))
      } catch (error) {
        console.error(error)
      }
    }

    function sortDevices(a: Device, b: Device) {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) {
        return -1
      } else if (aName > bName) {
        return 1
      } else {
        return 0
      }
    }

    ;(() => {
      fetchDevices()
    })()

    const active = computed(() => {
      return devices.value.filter((d) => d.active).sort(sortDevices)
    })

    const all = computed(() => {
      return devices.value.sort(sortDevices)
    })

    function getIndexById(id: number) {
      let index = indexes.get(id)
      if (index && devices.value[index]?.id != id) {
        //FIXME: find reason for invalid / duplicate Keys
        indexes.clear()
        index = undefined
      }
      if (index === undefined || index === -1) {
        const index = devices.value.findIndex((d) => d.id === id)
        indexes.set(id, index)
        return index
      }
      return index
    }

    function updateDevice(device: DeviceWithProperties) {
      const index = getIndexById(device.id)
      if (index > -1) {
        devices.value[index] = { ...device, properties: devices.value[index].properties }
      } else {
        devices.value.push(device)
      }
      updatedDevices.value.push(device.id)
    }

    function updateDeviceWithProperties(device: DeviceWithProperties) {
      const index = getIndexById(device.id)
      if (index > -1) {
        devices.value[index] = device
      } else {
        devices.value.push(device)
      }
    }

    //TODO disable subscription on mobile?
    async function subscribeToDeviceChanges() {
      const index = supabase.getSubscriptions().findIndex((s) => !s.topic.localeCompare('realtime:public:g4y_device'))
      if (index > -1) {
        if (supabase.getSubscriptions()[index].isErrored() || supabase.getSubscriptions()[index].isClosed()) {
          await supabase.getSubscriptions()[index].rejoin()
          fetchDevices()
        }
      } else {
        await supabase
          .from('g4y_device')
          .on('UPDATE', (payload) => {
            updateDevice(prepareDevice(payload.new))
          })
          .subscribe()
        const index = supabase.getSubscriptions().findIndex((s) => !s.topic.localeCompare('realtime:public:g4y_device'))
        if (index > -1)
          supabase.getSubscriptions()[index].onMessage('*', (msg: any) => console.log(new Date(), 'onMessage', msg))
      }
    }

    async function unsubscribeFromDeviceChanges() {
      const index = supabase.getSubscriptions().findIndex((s) => !s.topic.localeCompare('realtime:public:g4y_device'))
      if (index > -1) {
        await supabase.getSubscriptions()[index].unsubscribe()
      }
    }

    const getDeviceById = computed(() => {
      return (id: number): DeviceWithProperties | null => {
        const index = getIndexById(id)
        if (index > -1) {
          return devices.value[index]
        } else {
          return null
        }
      }
    })

    const getDevicesWithProperty = computed(() => {
      return (propertyTypeId: number): DeviceWithProperties[] => {
        return devices.value.filter((d) =>
          d.properties.some((p) => p.property_type_id === propertyTypeId && p.value_boolean === true)
        )
      }
    })

    return {
      devices,
      updatedDevices,
      all,
      active,
      subscribeToDeviceChanges,
      unsubscribeFromDeviceChanges,
      getDeviceById,
      getDevicesWithProperty,
      updateDeviceFromServerById,
    }
  },
  { persist: false }
)
