<template>
  <TreeSelect v-model="nodeSelected" :options="branches" class="w-full" />
</template>

<script setup lang="ts">
  import { useBranchOfficeStore } from '@/store/branchOffice'
  import { BranchOffice } from '@/types/extendDatabase'
  import TreeSelect from 'primevue/treeselect'
  import { computed } from 'vue'

  const branchOfficeStore = useBranchOfficeStore()
  const branches = computed(() => branchOfficeStore.branchOfficesAsTree)

  const selectedBranch = defineModel<BranchOffice>({ required: true })
  const nodeSelected = computed<{}>({
    get: () => {
      return { [selectedBranch.value.id.toString()]: selectedBranch.value.label }
    },
    set: (newNode) => {
      if (newNode) {
        selectedBranch.value =
          branchOfficeStore.branchOffices.find((b) => b.id.toString() === Object.keys(newNode)[0]) ??
          branchOfficeStore.branchOffices[0]
      }
    },
  })
</script>

<style scoped></style>
