import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserSettingsStore = defineStore(
  'userSettings',
  () => {
    const center = ref([7.77, 49.45])

    return { center }
  },
  {
    persist: true,
  }
)
