import { supabase } from '@/supabase'
import { UserRights } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

const DEFAULT_USER_RIGHTS: UserRights = {
  right_salary_review: false,
  right_devices_create: false,
  right_device_release: false,
  right_alerting: false,
  right_device_operating_hours: false,
}

export const useAuthStore = defineStore('auth', () => {
  let userRights: Ref<UserRights> = ref(DEFAULT_USER_RIGHTS)

  async function fetchUserRights() {
    try {
      const { data, error } = await supabase.from('user_rights').select('*').eq('id', supabase.auth.user()?.id).single()
      if (error) {
        if (
          !(
            error.details &&
            !error.details.localeCompare('Results contain 0 rows, application/vnd.pgrst.object+json requires 1 row')
          )
        )
          throw error
      } else {
        userRights.value = <UserRights>data
      }
    } catch (error) {
      console.trace(error)
    }
  }

  function resetUserRightsToDefault() {
    userRights.value = DEFAULT_USER_RIGHTS
  }

  return { userRights, fetchUserRights, resetUserRightsToDefault }
})
