<template>
  <div id="mobile-nav">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
  #mobile-nav {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: none;
    z-index: 999;

    .container {
      width: 90%;
      background-color: var(--neutrals-000);
      border-radius: 9px;
      display: flex;
      justify-content: space-around;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

      .icon-btn {
        height: 50px;
        width: 50px;
        margin: 0 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 0.7rem;
        color: var(--neutrals-800);
        > span {
          margin-top: 5px;
          margin-bottom: -5px;
        }
      }
      .active {
        color: var(--primary-color) !important;
      }

      .inactive {
        color: var(--neutrals-100) !important;
        pointer-events: none;
      }
    }
  }
</style>
