import { supabase } from '@/supabase'
import { definitions } from '@/generated/types/database'

export interface DevicePicture {
  id: number
  url: string
  urlInStorage?: string
}

export const getPicturesForDevice = async (id: number): Promise<DevicePicture[]> => {
  const pictures: DevicePicture[] = []
  try {
    const { data, error } = await supabase.from('g4y_device_picture').select('*').eq('device_id', id)
    if (error) throw error

    const pictureToCollect = <definitions['g4y_device_picture'][]>data

    for (const picture of pictureToCollect) {
      const { error, data } = await supabase.storage.from('device-picture').download(picture.url)
      if (error) return Promise.reject(error)
      pictures.push({
        id: picture.id,
        url: URL.createObjectURL(<Blob>data),
        urlInStorage: picture.url,
      })
    }
  } catch (error) {
    return Promise.reject(error)
  }

  return pictures
}
