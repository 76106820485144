<template>
  <div class="flex h-full flex-col-reverse sm:flex-col">
    <Navigation
      class="noPrint"
      v-if="!isSmallScreen"
      :navMain="navigation"
      :isAuthenticated="authenticated"
      :navUser="userNavigation"
      @showFeedbackForm="(n: any) => (showFeedbackForm = n)"
    />

    <div id="view" :class="{ mobile: isSmallScreen }">
      <Suspense>
        <template #default>
          <div class="h-full">
            <router-view></router-view>
            <teleport v-if="showFeedbackForm" to="body">
              <div class="absolute bottom-0 left-0 z-[1000] h-full w-full bg-gray-800 sm:top-0 sm:p-8">
                <Feedback @cancel="showFeedbackForm = false"></Feedback>
              </div>
            </teleport>
          </div>
        </template>
        <template #fallback> loading ... </template>
      </Suspense>
    </div>
  </div>
  <Toast />
  <DynamicDialog />
</template>

<script setup lang="ts">
  import { computed, Ref, ref, watch } from 'vue'
  import { supabase } from './supabase'
  import { useRouter } from 'vue-router'
  import Feedback from './components/Feedback.vue'
  import Navigation from './components/Navigation.vue'
  import Toast from 'primevue/toast'
  import { storeToRefs } from 'pinia'
  import { useAuthStore } from './store/auth'
  import { useUserDeviceStore } from './store/userDevice'
  import DynamicDialog from 'primevue/dynamicdialog'
  import { useRightsStore } from './store/rights'

  const authenticated: Ref<boolean> = ref(supabase.auth.user() != null)
  const router = useRouter()
  const showFeedbackForm = ref(false)
  const rightsStore = useRightsStore()
  const authStore = useAuthStore()

  const showRights = computed(
    () => rightsStore.rights.length > 1 || (rightsStore.rights.length === 1 && rightsStore.rights[0].role_id === 1)
  )
  const { userRights } = storeToRefs(authStore)
  const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)

  supabase.auth.onAuthStateChange((event) => {
    if (!event.localeCompare('SIGNED_OUT')) {
      authStore.resetUserRightsToDefault()
      authenticated.value = false
    } else if (!event.localeCompare('SIGNED_IN')) {
      authStore.fetchUserRights()
      authenticated.value = true
    }
  })

  const navigation = computed(() => {
    if (!authenticated.value) {
      return []
    }

    let navItems = [
      { name: 'Kartenansicht', path: '/', showInNav: true },
      // { name: 'Live Ortung', path: '/replay', showInNav: false, current: false },
      {
        name: 'Lohnplausibilisierung',
        path: '/salaryReview',
        showInNav: userRights.value.right_salary_review,
      },
      {
        name: 'Listenansicht',
        path: '/inventory',
        showInNav: true,
      },
      {
        name: 'Gerät anlegen',
        path: '/devices/create',
        showInNav: userRights.value.right_devices_create,
      },
      {
        name: 'Kundenverwaltung',
        path: '/customer',
        showInNav: showRights.value,
      },
      {
        name: 'Rechteverwaltung',
        path: '/rights',
        showInNav: showRights.value,
      },
    ]

    return navItems.filter((n) => n.showInNav)
  })

  // Check on resize
  window.addEventListener('resize', function () {
    useUserDeviceStore().updateSmallScreen()
  })

  watch(authenticated, (newValue) => {
    if (newValue) authStore.fetchUserRights()
  })

  if (authenticated.value) {
    authStore.fetchUserRights()
  }

  const signout = () => {
    supabase.auth.signOut()
  }

  const userNavigation = [
    {
      name: 'Passwort ändern',
      action: () => {
        router.push({ path: '/changePassword' })
      },
    },
    {
      name: 'Ausloggen',
      action: () => {
        signout()
      },
    },
  ]
</script>

<style lang="scss" scoped>
  #view {
    height: calc(100% - 4rem);
    margin-top: 4rem;

    &.mobile {
      height: 100%;
      margin-top: 0;
    }
  }
</style>
