import { PressState, PressStateText, PressLevelText, PressErrorText } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { supabase } from '@/supabase'
import { ref } from 'vue'

export const usePressStore = defineStore('press', () => {
  const pressStates = ref<PressState[]>([])
  const pressStateTexts = ref<PressStateText[]>([])
  const pressLevelTexts = ref<PressLevelText[]>([])
  const pressErrorTexts = ref<PressErrorText[]>([])

  let initDone = new Promise(async (resolve) => {
    await fetchPressStateTexts()
    await fetchPressLevelTexts()
    await fetchPressErrorTexts()
    await fetchPressStates()
    resolve(null)
  })

  async function fetchPressStates() {
    try {
      const { data, error } = await supabase.from('press_state').select('*')
      if (error) throw error
      pressStates.value = data
      supabase
        .from('press_state')
        .on('UPDATE', (payload) => {
          const updatedPressState = payload.new
          const index = pressStates.value.findIndex((state) => state.id === updatedPressState.id)
          pressStates.value[index] = updatedPressState
        })
        .subscribe()
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressStateTexts() {
    try {
      const { data, error } = await supabase.from('press_state_text').select('*')
      if (error) throw error
      pressStateTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressLevelTexts() {
    try {
      const { data, error } = await supabase.from('press_level_text').select('*')
      if (error) throw error
      pressLevelTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  async function fetchPressErrorTexts() {
    try {
      const { data, error } = await supabase.from('press_error_text').select('*')
      if (error) throw error
      pressErrorTexts.value = data
    } catch (error) {
      console.error(error)
    }
  }

  //TODO: add realtime update

  const getPressStateByAssetId = (assetId: number) => pressStates.value.find((state) => state.asset_id === assetId)

  const pressStateTextByStateId = (stateId: number): string =>
    pressStateTexts.value.find((state) => state.state_id === stateId)?.state_text || 'ERROR - should not happen'
  //FIXME: throw error
  const pressStateTextByAssetId = (assetId: number): string => {
    const state = getPressStateByAssetId(assetId)
    const stateId = state?.state
    if (!stateId) return '- nicht vorhanden -'
    //FIXME: handle state with id 0
    if (stateId === 1)
      return (
        pressErrorTexts.value.find((err) => err.error_id === state.error)?.error_text || 'ERROR - should not happen'
      )
    return pressStateTexts.value.find((state) => state.state_id === stateId)?.state_text || 'ERROR - should not happen'
  }

  const pressLevelTextByLevelId = (levelId: number): string =>
    pressLevelTexts.value.find((state) => state.level_id === levelId)?.level_text || 'ERROR - should not happen'
  //FIXME: throw error
  const pressLevelTextByAssetId = (assetId: number): string => {
    const levelId = getPressStateByAssetId(assetId)?.level
    if (levelId === undefined) return '- nicht vorhanden -'
    return pressLevelTexts.value.find((state) => state.level_id === levelId)?.level_text || 'ERROR - should not happen'
  }

  return {
    pressStates,
    fetchPressStates,
    getPressStateByAssetId,
    pressStateTextByStateId,
    pressStateTextByAssetId,
    pressLevelTextByAssetId,
    initDone,
  }
})
