<template>
  <div ref="mapRoot" class="h-full"></div>
  <div
    ref="popup"
    class="absolute bottom-1 -left-10 w-56 rounded border border-solid border-gray-700 bg-white px-1 text-sm font-light shadow-xl"
  ></div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, toRefs } from '@vue/runtime-core'
  import * as ol from 'ol'
  import Geometry from 'ol/geom/Geometry'
  import TileLayer from 'ol/layer/Tile'
  import VectorLayer from 'ol/layer/Vector'
  import OSM from 'ol/source/OSM'
  import VectorSource from 'ol/source/Vector'
  import { ref, watch } from 'vue'
  import { transformFromOl, transformToOl } from '@/controller/MapHelper'
  import { debounce } from 'lodash'
  import 'ol/ol.css'
  import { FeatureLike } from 'ol/Feature'
  import { Cluster } from 'ol/source'
  import { Style, Stroke, Fill, Text, Icon } from 'ol/style'
  import { boundingExtent } from 'ol/extent'

  import CircleStyle from 'ol/style/Circle'

  export default defineComponent({
    props: {
      assetFeatures: {
        // TODO maybe replace any with Geometry
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Array as () => Array<ol.Feature<any>>,
        required: true,
      },
      arrowFeatures: {
        // TODO maybe replace any with Geometry
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Array as () => Array<ol.Feature<any>>,
        required: true,
      },
      center: {
        type: Array as () => Array<number>,
        default() {
          return [7.77, 49.45]
        },
      },
      emitFeaturesInExtent: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['updateCenter', 'featureClicked', 'featuresInExtent'],
    setup(props, ctx) {
      const mapRoot = ref(null)
      const popup = ref(null)

      const { assetFeatures, arrowFeatures, center, emitFeaturesInExtent } = toRefs(props)
      let zoom = 13
      const animationDuration = 1000
      const ZOOM_LEVEL_SWITCH = 16

      let myMap: ol.Map
      //@ts-ignore
      let defaultLayer, clusterLayer, arrowLayer: VectorLayer<VectorSource<Geometry>>

      let useArrows = true
      let useCluster: boolean = false
      if (import.meta.env.VITE_USE_OPENLAYERS_CLUSTER != undefined) {
        useCluster = import.meta.env.VITE_USE_OPENLAYERS_CLUSTER === 'true'
      } else console.log('VITE_USE_OPENLAYERS_CLUSTER missing in .env, using false as default')

      //@ts-ignore
      let assetSource: VectorSource = new VectorSource({
        features: [],
      })

      //@ts-ignore
      let arrowSource: VectorSource = new VectorSource({
        features: [],
      })

      defaultLayer = new VectorLayer({
        source: assetSource,
        minZoom: useCluster ? ZOOM_LEVEL_SWITCH : undefined,
        zIndex: 2,
      })

      const clusterSource = new Cluster({
        distance: 25,
        source: assetSource,
      })

      //@ts-ignore
      arrowLayer = new VectorLayer({
        //@ts-ignore
        source: arrowSource,
        zIndex: 1,
      })

      const styleCache: Style[] = []
      //@ts-ignore
      clusterLayer = new VectorLayer({
        //@ts-ignore
        source: clusterSource,
        maxZoom: ZOOM_LEVEL_SWITCH,
        zIndex: 3,
        style: function (feature) {
          if (!myMap.getView()?.getZoom()) return
          //@ts-ignore
          const featureInCluster = feature.get('features').filter((f) => f.getStyle().getImage())
          const size = featureInCluster.length
          if (size === 0) return
          if (size === 1) {
            return featureInCluster[0].getStyle()
          }
          let style = styleCache[size]
          if (!style) {
            // TODO: move to mapHelper
            style = new Style({
              image: new CircleStyle({
                radius: 8 * Math.pow(size, 0.25),
                fill: new Fill({
                  color: '#007399',
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: '#fff',
                }),
                stroke: new Stroke({
                  color: '#000',
                  width: 3,
                }),
                offsetY: 2,
                scale: 1.2,
              }),
            })
            styleCache[size] = style
          }
          return style
        },
      })

      onMounted(() => {
        let view = new ol.View({
          center: transformToOl(center.value),
          zoom,
        })

        view.on(
          'change:center',
          debounce(() => {
            let newCenter = view.getCenter()
            if (newCenter) {
              ctx.emit('updateCenter', transformFromOl(newCenter))
            }
          }, 250)
        )

        watch(center, (center) => {
          const vc = view.getCenter()
          if (vc) {
            const viewCenter = transformFromOl(vc)
            if (viewCenter[0] != center[0] && viewCenter[1] != center[1]) {
              view.animate({
                duration: animationDuration,
                center: transformToOl(center),
                zoom: ZOOM_LEVEL_SWITCH + 1,
              })
            }
          }
        })

        const overlay = new ol.Overlay({
          //@ts-ignore
          element: popup.value,
        })

        const layers = [new TileLayer({ source: new OSM() }), defaultLayer]
        //@ts-ignore
        if (useCluster) layers.push(clusterLayer)
        //@ts-ignore
        if (useArrows) layers.push(arrowLayer)

        myMap = new ol.Map({
          //@ts-ignore
          target: mapRoot.value,
          layers: layers,
          overlays: [overlay],
          view,
        })

        watch(
          assetFeatures,
          () => {
            setTimeout(async () => {
              if (assetFeatures.value.length === 0) await new Promise((r) => setTimeout(r, 500))
              if (assetFeatures.value.length === 0) await new Promise((r) => setTimeout(r, 500))
              assetSource.clear()
              arrowSource.clear()
              assetSource.addFeatures(assetFeatures.value)
              arrowSource.addFeatures(arrowFeatures.value)
              if (emitFeaturesInExtent.value) getAndEmitFeaturesInExtent(myMap)
            }, 500)
          },
          { immediate: true }
        )

        myMap.on('click', (event) => {
          const hovered = myMap.forEachFeatureAtPixel(event.pixel, (feature) => feature)
          if (view.getZoom()! < ZOOM_LEVEL_SWITCH && useCluster) {
            const clusterHovered = hovered?.get('features')
            if (!clusterHovered) return
            if (clusterHovered.length === 1) {
              ctx.emit('featureClicked', clusterHovered[0])
            } else {
              //@ts-ignore
              const extent = boundingExtent(clusterHovered.map((r) => r.getGeometry().getCoordinates()))
              view.fit(extent, {
                duration: animationDuration,
                padding: [50, 50, 50, 50],
                maxZoom: ZOOM_LEVEL_SWITCH + 1,
              })
            }
          } else if (view.getZoom()! > ZOOM_LEVEL_SWITCH || !useCluster) {
            if (hovered) ctx.emit('featureClicked', hovered)
          } else {
            //ZOOM_LEVEL_SWITCH == view.getZoom()
            const singleHovered = hovered?.get('features')[0]
            if (singleHovered) ctx.emit('featureClicked', singleHovered)
          }
        })

        myMap.on(
          'pointermove',
          debounce((event) => {
            let hoveredFeatures: FeatureLike[] = []
            myMap.forEachFeatureAtPixel(event.pixel, (f) => {
              hoveredFeatures.push(f)
            })
            if (hoveredFeatures.length > 0) {
              let innerHTML = ''
              let counter = 0
              for (const f of hoveredFeatures) {
                if (view.getZoom()! <= ZOOM_LEVEL_SWITCH && useCluster) {
                  for (const ff of f.get('features')) {
                    if (counter > 20) break
                    if (ff.get('name') && ff.getStyle().getImage()) {
                      innerHTML += counter < 20 ? `<p>${ff.get('name')}</p>` : `<p>...</p>`
                      counter++
                    }
                  }
                } else {
                  //@ts-ignore
                  if (f.get('name') && f.getStyle().getImage()) innerHTML += `<p>${f.get('name')}</p>`
                }
              }
              if (innerHTML.length) {
                //@ts-ignore
                popup.value.innerHTML = innerHTML
                overlay.setPosition(event.coordinate)
              } else {
                overlay.unset('position')
              }
            } else {
              overlay.unset('position')
            }
          }, 50)
        )

        let getAndEmitFeaturesLoopRunning = false
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getAndEmitFeaturesInExtent = (map: any) => {
          const featuresInExtent: ol.Feature<Geometry>[] = []
          const extend = map.getView().calculateExtent()
          assetSource.forEachFeatureInExtent(extend, (feature) => {
            featuresInExtent.push(feature)
          })
          ctx.emit('featuresInExtent', featuresInExtent)
          if (!getAndEmitFeaturesLoopRunning) {
            getAndEmitFeaturesLoopRunning = true
            setTimeout(() => {
              getAndEmitFeaturesLoopRunning = false
              if (emitFeaturesInExtent.value) getAndEmitFeaturesInExtent(myMap)
            }, 5000)
          }
        }

        myMap.on(
          'moveend',
          debounce((event) => {
            if (emitFeaturesInExtent.value) {
              getAndEmitFeaturesInExtent(event.map)
            }
          }, 1)
        )

        watch(emitFeaturesInExtent, () => {
          if (emitFeaturesInExtent.value) getAndEmitFeaturesInExtent(myMap)
        })
      })
      return {
        mapRoot,
        popup,
      }
    },
  })
</script>
