import { definitions } from '@/generated/types/database'
import { Device, GroupWithSupportedProperties } from '@/types/extendDatabase'

export interface Device_Group_Type {
  id: number
  label: string
  type_id: number
}

export const ALL = -1
export const GPS_TYPE = 303
export const BLE_TYPE = 624
export const GPS_TYPE_GROUP: Device_Group_Type = { id: GPS_TYPE, label: 'GPS', type_id: GPS_TYPE }
export const TYPE_GROUP_ALL: Device_Group_Type = { id: ALL, label: 'beide', type_id: ALL }
const GPS_GROUP_ID_SMALLER_THAN = 100

export const DEVICE_TYPES: Device_Group_Type[] = [
  GPS_TYPE_GROUP,
  { id: ALL, label: 'beide', type_id: ALL },
  { id: BLE_TYPE, label: 'Bluetooth', type_id: BLE_TYPE },
]

export const DEVICE_GROUP_ALL: GroupWithSupportedProperties = {
  id: -1,
  label: 'Alle',
  type: ALL,
  utilization: false,
  supported_properties: [],
}

export const filterGrouspByType = (type: Device_Group_Type) => {
  return (group: { id: number; type: number }): boolean => {
    if (type.id === ALL || group.type === ALL || type.type_id === group.type) {
      return true
    } else {
      return false
    }
  }
}

export const sortGroupsByTypeAndByName = (
  a: definitions['g4y_device_group'],
  b: definitions['g4y_device_group']
): number => {
  if (a.type === b.type) {
    return a.label.toLowerCase() === b.label.toLowerCase() ? 0 : a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
  } else {
    return a.type > b.type ? 1 : -1
  }
}

export const filterDevicesByGroup = (group: { id: number; type: number }, type: Device_Group_Type) => {
  return (device: Device): boolean => {
    if (!group || device.group_id === group.id) return true
    if (group.type === ALL) {
      if (type.type_id === ALL) return true
      if (type.type_id === GPS_TYPE && device.group_id) return device.group_id < GPS_GROUP_ID_SMALLER_THAN
      if (type.type_id === BLE_TYPE && device.group_id) return device.group_id >= GPS_GROUP_ID_SMALLER_THAN
      return false
    }
    return false
  }
}

export const sortDevicesByDistanceToCenter = (center: number[]) => {
  return (a: Device, b: Device): number => {
    const distA = getDistacne(center, a.position.coordinates)
    const distB = getDistacne(center, b.position.coordinates)
    if (distA < distB) {
      return -1
    } else if (distA > distB) {
      return 1
    } else {
      return 0
    }
  }
}

function getDistacne(coordinate1: number[], coordinate2: number[]): number {
  const lat1 = coordinate1[1]
  const lon1 = coordinate1[0]
  const lat2 = coordinate2[1]
  const lon2 = coordinate2[0]

  //see: https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3 // metres
  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres

  return d
}
