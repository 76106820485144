import { supabase } from '@/supabase'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

const DEFAULT_GLOBAL_SETTINGS: { identifier: string; use: boolean }[] = [
  { identifier: 'useBranchOffices', use: false },
  { identifier: 'useLiveTrackingSelectButton', use: true },
  { identifier: 'useGroups', use: true },
  { identifier: 'useOperatingHours', use: true },
  { identifier: 'useUtilizationChart', use: true },
]

export const useGlobalSettingsStore = defineStore('globalSettings', () => {
  let globalSettings: Ref<{ identifier: string; use: boolean }[]> = ref(DEFAULT_GLOBAL_SETTINGS)

  async function fetchGlobalSettings() {
    try {
      const { data, error } = await supabase.from('settings_global').select('*')
      if (error) throw error
      for (const entry of data) {
        let index = globalSettings.value.findIndex((s) => s.identifier === entry.identifier_text)
        if (index === -1) continue
        globalSettings.value[index].use = entry.use
      }
    } catch (error) {
      console.error(error)
    }
  }
  ;(() => {
    fetchGlobalSettings()
  })()

  return { globalSettings, fetchGlobalSettings }
})
