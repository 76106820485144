import { BLE_TYPE, GPS_TYPE, sortGroupsByTypeAndByName } from '@/helper/filterAndSorting'
import { supabase } from '@/supabase'
import { GroupWithSupportedProperties } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

export const useDeviceGroupStore = defineStore(
  'deviceGroup',
  () => {
    const groups: Ref<GroupWithSupportedProperties[]> = ref([])
    const groupsAsTree: Ref<GroupWithSupportedProperties[]> = ref([])

    async function fetchDeviceGroups() {
      try {
        const { data, error } = await supabase
          .from('g4y_device_group')
          .select('*, supported_properties:device_group_device_property_type(*)')
        if (error) throw error
        const fetchedGroups: GroupWithSupportedProperties[] = data

        groups.value = fetchedGroups
        groupsAsTree.value = combineGroups(fetchedGroups)
      } catch (error) {
        console.error(error)
      }
    }

    function combineGroups(groups: GroupWithSupportedProperties[]): GroupWithSupportedProperties[] {
      const groupsAsTree: GroupWithSupportedProperties[] = []
      const groupMap = new Map<number, GroupWithSupportedProperties>()

      for (const group of groups) {
        groupMap.set(group.id, group)
      }

      const parents: GroupWithSupportedProperties[] = groups.filter((group) => group.parent_id === null)
      for (const group of groups) {
        if (group.parent_id && !groupMap.has(group.parent_id)) {
          parents.push(group)
        }
      }

      if (parents.length === 0) {
        console.error('no parent', parents)
        return []
      }

      for (const parent of parents) {
        addChildrenToParentGroup(parent)
      }

      if (parents.length === 1) {
        groupsAsTree.push(parents[0])
      } else {
        const groupAll: GroupWithSupportedProperties = {
          id: -1,
          label: 'Alle',
          type: 303,
          supported_properties: [],
          utilization: false,
          own_and_subgroup_ids: [],
          sub_groups: parents,
        }
        groupsAsTree.push(groupAll)
      }
      function addChildrenToParentGroup(group: GroupWithSupportedProperties) {
        if (group.child_ids != undefined) {
          for (const id of group.child_ids) {
            const childGroup = groupMap.get(id)
            if (childGroup) {
              addChildrenToParentGroup(childGroup)
              if (group.sub_groups == undefined) group.sub_groups = []
              group.sub_groups.push(childGroup)
            }
          }
          return group
        }
      }

      return groupsAsTree
    }

    const findGroupById = (
      id: number,
      groups: GroupWithSupportedProperties[]
    ): GroupWithSupportedProperties | undefined => {
      for (const group of groups) {
        if (group.id === id) {
          return group
        } else if (group.sub_groups) {
          const foundGroup = findGroupById(id, group.sub_groups)
          if (foundGroup) {
            return foundGroup
          }
        }
      }
    }

    ;(() => {
      fetchDeviceGroups()
    })()

    const groupById = computed(() => {
      return (id: number | undefined): GroupWithSupportedProperties => {
        return id !== undefined
          ? groups.value.find((g) => g.id === id) ?? {
              id,
              label: 'exisiteriert nicht',
              type: -1,
              utilization: false,
              supported_properties: [],
            }
          : { id: -1, label: 'exisitiert nicht', type: -1, utilization: false, supported_properties: [] }
      }
    })

    const idsOfSubGroups = computed(() => {
      return (groupId: number | undefined): number[] => {
        const ids: number[] = []
        ids.push(...groups.value.filter((g) => g.parent === groupId).map((g) => g.id))
        for (const id of ids) {
          ids.push(...idsOfSubGroups.value(id))
        }
        return ids
      }
    })

    const idsOfSubGroupsAndOwn = computed(() => {
      return (groupId: number | undefined): number[] => {
        const ids: number[] = []
        if (!groupId) return ids
        ids.push(groupId)
        ids.push(...idsOfSubGroups.value(groupId))
        return ids
      }
    })

    const isBleType = computed(() => {
      return (groupId: number | undefined) => {
        return groups.value.find((g) => g.id === groupId)?.type === BLE_TYPE
      }
    })

    const isGPSType = computed(() => {
      return (groupId: number | undefined) => {
        return groups.value.find((g) => g.id === groupId)?.type === GPS_TYPE
      }
    })

    return { groups, groupsAsTree, groupById, isBleType, isGPSType, idsOfSubGroupsAndOwn }
  },
  { persist: true }
)
